<template>
    <div class="content-box">
        <div v-loading="loading" class="mx-auto login p-20 box-shadow">
            <div class="header text-center mb-25">
                <h2 class="mb-0">{{ $t('login.title') }}</h2>
                <p class="mt-10 mb-0 color-6 font-size-14">{{ $t('login.dont_have_an_account') }} <el-link class="color-primary is-link ml-3" @click="$nav.replace('register')">{{ $t('login.to_register') }}</el-link></p>
            </div>
            <el-divider />
            <el-form ref="ruleForm" :model="formData" :show-message="false" :status-icon="true" :rules="rules">
                <el-form-item prop="email">
                    <el-input v-model="formData.email" prefix-icon="el-icon-user" :placeholder="$t('login.email')" />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.password" prefix-icon="el-icon-lock" :placeholder="$t('login.password')" show-password />
                </el-form-item>
                <el-form-item prop="captcha">
                    <el-input v-model="formData.captcha" prefix-icon="el-icon-s-check" :placeholder="$t('login.captcha')">
                        <template slot="append">
                            <img v-if="captchaSrc" class="captcha" :src="captchaSrc" :alt="$t('login.captcha')"  @click="getCaptcha()">
                            <el-button v-else plain type="success" native-type="button" @click="getCaptcha()">{{ $t('login.click_me_show') }}</el-button>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-divider />
            <div class="mt-30 mx-20">
                <el-button class="w-100" type="primary" @click="submitForm">{{ $t('login.sign_in_now') }}</el-button>
            </div>
            <div class="mt-30 mb-20 text-center font-size-14">
                <el-link class="color-primary is-link" @click="$nav.replace('forget')">{{ $t('login.forget_password') }}</el-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            captchaLoading: false,
            captchaSrc: null,
            formData: {
                email: this.$store.state.userEmail,
                password: '',
                captcha: '',
                captcha_token: null
            },
            rules: {
                email: [
                    { required: true, type: 'email', message: 'Please enter Email address', trigger: 'change' }
                ],
                password: [
                    { required: true, type: 'string', min: 6, max: 32, message: 'More than 6 ~ 32 characters', trigger: 'change' }
                ],
                captcha: [
                    { required: true, type: 'string', len: 5, message: 'More than 5 characters', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        getCaptcha: async function() {
            if (this.captchaLoading) return
            this.captchaLoading = true
            try{
                const { data } = await this.$request.get('login/form_captcha')
                this.formData.captcha_token = data.token
                this.formData.captcha = ''
                this.captchaSrc = data.data
            }catch(e){
                //
            }
            this.captchaLoading = false
        },
        submitForm: async function () {
            await this.$refs.ruleForm.validate()
            this.loading = true
            try {
                const { data } = await this.$request.post('login', this.formData)
                this.$store.commit('setUserInfo', data)
                this.$nav.back()
            } catch (error) {
                this.getCaptcha(false)
            }
            this.loading = false
        }
  }
}
</script>
<style lang="scss" scoped>
.login{
    width: 400px;
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: white;
    color: #333;
    border-radius: 8px;
}
img.captcha{
    margin-left: -20px;
    margin-right: -20px;
}
</style>